import Plyr from 'plyr';
import {shadow} from "../utils/Shadow";
import '../../../../node_modules/plyr/dist/plyr.css'

class Video {
    constructor() {
        this.isInit = false;
        this.isView = false;
        this.playerInstance = null;
        this.lastContentElement = null;
    }

    init() {
        if (!this.isInit) {
            this.playerWrap = document.getElementById('playerWrap');

            this.runButtons = document.querySelectorAll('[data-play-video]');
            this.onHandlerRunButtonClick = this.handlerRunButtonClick.bind(this);
            this.onHandlerBodyKeydown = this.handlerBodyKeyDownEvent.bind(this);
            this.onHandlerWindowResize = this.handlerWindowResize.bind(this);
            this.onHandlerShadowClick = this.handlerShadowClick.bind(this);

            this.isInit = true;
            this.bind();
        }
    }

    destroy() {
        if (this.isInit) {
            this.isInit = false;
            this.unbind();
        }
    }

    bind() {
        this.runButtons.forEach(el => {
            el.addEventListener('click', this.onHandlerRunButtonClick);
        });
    }

    unbind() {
        this.runButtons.forEach(el => {
            el.removeEventListener('click', this.onHandlerRunButtonClick);
        });
    }

    handlerRunButtonClick(e) {
        e.preventDefault();
        this.view(e.currentTarget.dataset, e.currentTarget.closest('.content'));
    }

    handlerBodyKeyDownEvent(e) {
        if (e.keyCode === 27) {
            this.close();
        }
    }

    handlerWindowResize() {
        this.fixSizePositions();
    }

    handlerShadowClick(e) {
        e.preventDefault();
        this.close();
    }

    close() {
        if (!this.isView) {
            return;
        }

        document.removeEventListener('keydown', this.onHandlerBodyKeydown);
        window.removeEventListener('resize', this.onHandlerWindowResize);
        shadow(false, this.onHandlerShadowClick);

        if (this.playerInstance) {
            this.playerInstance.destroy();
            this.playerInstance = null;
            document.getElementById('playerBox').remove();
        }

        this.playerWrap.style.display = 'none';
        this.isView = false;
    }

    view(dataSets, contentElement) {
        this.close();

        this.lastContentElement = contentElement;
        this.isView = true;
        document.addEventListener('keydown', this.onHandlerBodyKeydown);
        window.addEventListener('resize', this.onHandlerWindowResize);
        shadow(true, this.onHandlerShadowClick);

        if (window.playerCode === 'vk' && dataSets.playVkVideo) {
            this.playerWrap.innerHTML= `<iframe src="https://vk.com/video_ext.php?oid=-${dataSets.playVkVideo}&autoplay=0" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>`;
        } else if (window.playerCode === 'rutube' && dataSets.playRutubeVideo) {
            this.playerWrap.innerHTML= `<iframe src="https://rutube.ru/play/embed/${dataSets.playRutubeVideo}/" width="100%" height="100%" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>`;
        } else if (window.playerCode === 'dzen' && dataSets.playDzenVideo) {
            this.playerWrap.innerHTML= `<iframe src="https://dzen.ru/embed/${dataSets.playDzenVideo}?from_block=partner&from=zen&mute=0&autoplay=0&tv=0" width="100%" height="100%" allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media" frameborder="0" scrolling="no" allowfullscreen></iframe>`;
        } else {
            // Приходится пересоздавать постоянно playerBox, иначе работает некорректно
            // При выборе других видео, всегда будет показываться то, которое было "запрошено" первым
            const playerBox = document.createElement('div');
            playerBox.id = 'playerBox';
            playerBox.dataset.plyrProvider = 'youtube';
            playerBox.dataset.plyrEmbedId = dataSets.playVideo;

            this.playerWrap.appendChild(playerBox);
            this.playerInstance = new Plyr(playerBox, {
                disableContextMenu: true,
                settings: ['captions', 'quality', 'speed', 'loop'],
                youtube: {
                    listType: 'playlist',
                    loop: 1,
                    ecver: 2,
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                    controls: 1
                }
            });
        }

        this.fixSizePositions();
    }

    fixSizePositions() {
        let width = this.lastContentElement.clientWidth;
        let height = 1080 * (width / 1920);
        let separatorLength = 50;

        if (height + separatorLength > document.documentElement.clientHeight) {
            height = document.documentElement.clientHeight - separatorLength;
            width = 1920 * (height / 1080);
        }

        let marginLeft = width / 2;
        let marginTop = height / 2;

        this.playerWrap.style.display = 'block';
        this.playerWrap.style.width = `${width}px`;
        this.playerWrap.style.height = `${height}px`;
        this.playerWrap.style.marginLeft = `-${marginLeft}px`;
        this.playerWrap.style.marginTop = `-${marginTop}px`
    }
}

export default Video;